import { useEffect, useRef, useState } from "react";
import { FiCalendar } from "react-icons/fi";
import { Range } from "react-date-range";
import moment from "moment";
import { useSearchQueryStore } from "store/useSearchQueryStore";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import "moment/locale/ar";
import DatePicker from "./datePicker";
import { safelyArabicToEnglish } from "utils/helpers/date-helper";

function SelectDatesCard() {
  const [showPicker, setShowPicker] = useState(false);
  const { setDatesQuery, checkInDate, checkOutDate }: any = useSearchQueryStore();
  const { pathname, locale } = useRouter();
  const { t: translate } = useTranslation();

  const [state, setState] = useState<Range[]>([
    {
      startDate: new Date(checkInDate),
      endDate: new Date(checkOutDate),
      key: "selection",
    },
  ]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [currentWidth, setCurrentWidth] = useState<number | null>(null);

  useEffect(() => {
    moment.locale(locale);
    setState([
      {
        startDate: new Date(checkInDate),
        endDate: new Date(checkOutDate),
        key: "selection",
      },
    ]);

    setStartDate(moment(safelyArabicToEnglish(state[0].startDate)).format("ddd, MMM Do"));
    setEndDate(state[0].endDate ? moment(safelyArabicToEnglish(state[0].endDate)).format("ddd, MMM Do") : "");

    setCurrentWidth(window.innerWidth);
  }, [checkInDate, checkOutDate, locale]);

  const pickerDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (pickerDivRef.current && !pickerDivRef.current.contains(event.target as Node)) {
        setShowPicker(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`relative p-4 bg-white shadow-lg shadow-neutral md:rounded-lg ${
        pathname === "/" ? "lg:rounded-full p-4" : "lg:rounded-3xl h-16 p-3 px-3"
      }`}
    >
      <div
        onClick={() => setShowPicker((prevState) => !prevState)}
        className="flex gap-2 cursor-pointer"
      >
        <FiCalendar className="rounded-none opacity-50" size={24} />
        <div>
          <h3 className="text-xs opacity-50">
            {translate("searchBar.Check in - Check out")}
          </h3>
          <h3 className="mt-2 text-xs">
            {startDate} - {endDate}
          </h3>
        </div>
      </div>
      {showPicker && (
        <div className={`absolute left-0 z-50 mt-5 shadow-xl lg:-left-2 xl:-left-36 shadow-neutral ${locale}`} ref={pickerDivRef}>
          <DatePicker
            locale={locale}
            setDatesQuery={setDatesQuery}
            currentWidth={currentWidth}
            state={state}
            setState={setState}
          />
        </div>
      )}

      
    </div>
  );
}

export default SelectDatesCard;
